<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O distrito de Camargos pertence ao município de Mariana-MG e
                possui como subdistrito Bicas a nordeste do seu território.
                Situa-se próximo da Serra do Caraça, na estrada que liga Mariana
                ao subdistrito de Bento Rodrigues (abrangido administrativamente
                pelo distrito de Santa Rita Durão). Está distante cerca de 19 km
                do centro de Mariana e a 135 km de Belo Horizonte, capital do
                estado de Minas Gerais.
              </p>
            </template>
          </Card>

          <Card
            title="Processo de formação do povoamento"
            titleColor="#000"
            borderColor="#e06919"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                A fundação da Freguesia de Camargos no alto do Rio Gualaxo do
                Norte, aponta para o alcaide-mor (antigo governador de uma
                província) José de Camargo Pimentel e seus três sobrinhos,
                Tomás, João e Fernando Lopes de Camargo, naturais de São Paulo,
                sendo os primeiros a chegarem nesta região. Os irmãos Camargos
                faziam parte da primeira expedição chefiada por Antônio Dias de
                Oliveira, um dos primeiros a minerar e ocupar a sede de Ouro
                Preto no final do século XVII.<sup>1</sup>
              </p>
              <p>
                Assim como Antônio Pereira, distrito de Ouro Preto onde nasce o
                curso d’água, Ponte do Gama, Bento Rodrigues e Teixeira (atual
                Paracatu), Camargos teve sua formação às margens do Rio Gualaxo
                do Norte. O povoado surgiu no início do século XVIII quando os
                três irmãos Camargos encontraram ouro no ribeirão e se
                estabeleceram no local. Seguindo os caminhos dos primeiros
                exploradores de Camargos, o povoamento da comunidade vizinha
                Bento Rodrigues iniciou-se por volta de 1702, recebendo também o
                nome de seu fundador. Outros povoados, conjuntamente, foram se
                formando ao norte do Rio Gualaxo do Norte no século XVIII, a
                exemplo de Inficcionado (atual Santa Rita Durão), Paulo Moreira
                (atual município de Alvinópolis) e Águas Claras do Lobo (atual
                Águas Claras).
              </p>
            </template>
          </Card>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_01.png"
          />
          <p style="font-size: 0.6em; text-align: center;">
            Registro fotográfico da Festa de Santa Cruz ao redor do cruzeiro de
            pedras. Foto: Flora Passos, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_02.png"
          />
          <p style="font-size: 0.6em; text-align: center;">
            Vista de trecho da estrada que conduz ao distrito de Camargos, onde
            se observa as margens da via enfeitadas, assim como o cruzeiro à
            esquerda da imagem. Foto: Flora Passos, 2018.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                É importante considerar que a chegada constante de novos grupos
                que se arriscavam em busca de ouro naquela época teve como
                obstáculo as precárias condições de abastecimento da região,
                levando ao abandono do Arraial de Nossa Senhora do Carmo (atual
                Mariana), primeiramente, entre 1697 e 1698, e depois entre 1701
                e 1702. A colonização de regiões próximas, como Camargos, São
                Caetano (atual Monsenhor Horta) e Furquim contribuiu para a
                fixação do núcleo urbano de Mariana, conciliando a exploração
                aurífera às atividades agropecuárias, dando origem a novos
                povoados.
              </p>
              <p>
                A ocupação original de Camargos ocorreu com a formação de
                fazendas voltadas para a produção agrícola e de criação de
                animais, assim como em virtude da extração de ouro. Apesar da
                porcentagem de metal retirada no local ser grande, representava
                somente cerca de 2% da quantidade extraída em Mariana entre 1721
                e 1733. Por outro lado, a produção de roçados como milho,
                feijão, cana de açúcar, mandioca, amendoim e arroz eram bastante
                significativos, da mesma forma que os cultivos de horta onde se
                plantavam couve, alface, agrião, quiabo, cebola, alho e
                gengibre. Nos pomares se sobressaiam as bananeiras, laranjeiras,
                limeiras, limoeiros, parreiras e figueiras, que também ajudavam
                a complementar a dieta dos moradores dos sítios, fazendas e
                residências do povoado. Quanto à pecuária, se destacava a
                criação de porcos, bois, cavalos, bestas, galinhas e patos. Além
                de Camargos, essas atividades agropecuárias eram comuns a todas
                as povoações existentes no século XVIII ao longo do Rio Gualaxo
                do Norte paralelamente à extração de ouro.
              </p>
              <p>
                Nas áreas de mineração, destacam-se estruturas como as rodas
                d’água, utilizadas para levar água até o alto das montanhas na
                técnica chamada “grupiara” ou “a céu aberto”. Os garimpeiros
                permaneciam nos locais de cotas mais baixas com suas bateias,
                aguardando que o metal descesse com a água e o cascalho. Também
                era comum a presença de mundéus na região denominada de
                “lavras”, como se a mineração fosse algo como lavrar a terra dos
                morros, removendo-a para que se soltassem os torrões de
                tapunhacanga ou canga. Os torrões eram então triturados e
                levados para o ouro ser apurado nos mundéus ou tanques de água.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Nos sítios e fazendas, as estruturas para realização de
                atividades produtivas remetem, por exemplo, aos engenhos, onde
                se produzia açúcar e aguardente, mercadoria com excelente
                comercialização e lucratividade na região. Havia ainda olarias e
                fornos para produção de elementos construtivos de barro como
                telhas. Entre a produção artesanal, estava a tecelagem, e, para
                tanto, eram utilizados teares ou rocas; para a fabricação de
                peças de ferro, valia-se das forjas. Para a conservação de
                grãos, eram usados os paióis e as antigas senzalas do período
                colonial, além dos moinhos d’água para triturar grãos e produzir
                geralmente fubá de milho.
              </p>
              <p>
                Ainda no período colonial, na região Mariana encontrava-se
                também a plantação de fumo, outro produto que possuía boa
                comercialização. No entorno das áreas de plantação e produção,
                encontravam-se as matas e capoeiras, espaços que poderiam ser
                utilizados ao longo do tempo para a expansão das atividades
                rurais.
              </p>
              <p>
                Nas vendas que funcionavam em Camargos entre 1718 e 1724, eram
                encontrados produtos como aguardente, açúcar, melado, banana,
                fumo, toucinho, manteiga de porco, entre outros que
                possivelmente seriam produzidos no distrito e seus arredores.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_03.png"
          />
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_bento_01.png"
          />
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Essa condição da economia onde a produção agrícola e de criação
                de animais era significativa possibilitou que Camargos superasse
                a fase de decadência da extração de ouro vivenciada a partir de
                meados do século XVIII e desenvolvesse ainda mais as outras
                atividades. Cabe ressaltar que a prática minerária sempre esteve
                presente, mesmo depois de seu declínio, e permaneceu pelos
                séculos seguintes em menores proporções paralelamente às
                atividades agropecuárias.
              </p>
              <p>
                A Freguesia de Camargos possuía uma população pequena em relação
                a outros núcleos de localidades de Mariana do período colonial,
                a exemplo da própria sede. A relação entre a população livre,
                liberta e escravizada era de 1/3 para cada uma. No que diz
                respeito à questão racial, os moradores de Camargos
                classificados como “brancos” representavam 5% da população; já
                os mulatos e negros abrangiam a maioria (95%), divididos em
                pardos, crioulos e “africanos pretos” (24%). Enquanto os
                primeiros haviam nascido na colônia (Portugal), estes últimos
                eram africanos que chegaram a Camargos durante a década final do
                século XVIII e início do XIX.
              </p>
              <p>
                A chegada de novos grupos de pessoas escravizadas nas décadas
                finais do século XVIII demonstra que existiam em Camargos
                habitantes dispostos a investir na aquisição de escravizados
                africanos mesmo em meio ao declínio da produção do ouro que se
                abateu sobre Minas Gerais nesta época. Por outro lado, uma
                parcela significativa dos moradores se apressou em negociar com
                as pessoas mantidas escravizadas as suas cartas de alforrias.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Exemplificando que em Camargos havia diversas atividades
                produtivas e ocupações, não se limitando somente à extração de
                ouro, no período colonial, mais precisamente já no século XIX,
                encontravam-se párocos, mineradores, criadores, administradores,
                ferreiros, negociantes, tropeiros, lavadeiras, estalajadeiros,
                carapinas (<router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_casas_oficio_carpinteiro' }"
                  >carpinteiros</router-link
                >
                de construções rurais), mineiros, peneireiras, costureiras.
                Estes dois últimos ofícios eram relacionados à produção de
                tecidos grossos negociados na região minerária para a produção
                das roupas de escravizados e dos sacos que serviam para guardar
                grãos, como milho, feijão, amendoim e arroz. Uma quantidade
                expressiva de “mineiros” fazia parte das ocupações da época, em
                que Minas Gerais passava pela crise da extração de ouro,
                demonstrando que a atividade sobreviveu em algumas localidades e
                continuou a ser importante para a economia de algumas
                freguesias, a exemplo de Camargos.
              </p>
              <p>
                Cabe ressaltar que, desde a formação dos povoados da região,
                havia estreitas ligações socioeconômicas entre eles, que
                perduraram ao longo dos séculos. Tanto pela proximidade, quanto
                pela interação econômica vantajosa, Camargos e a vizinha Bento
                Rodrigues possuíam relações comerciais e de prestação de
                serviços até o rompimento da barragem de Fundão. Além do setor
                econômico que possibilitava certa autonomia local, entre as
                comunidades havia fortes laços sócio-culturais, sendo que os
                moradores de cada localidade frequentavam as festas e
                celebrações tradicionais e religiosas da comunidade vizinha.
                Tantos anos de convívio formaram e estreitaram as amizades e
                confiança entre a população, que teve os laços cortados diante
                do desastre–crime. De acordo com relatos de moradores de
                Camargos, do distrito eram ouvidos os folguedos das festividades
                de Bento e depois do rompimento, restou a falta da alegria e dos
                sons da comunidade vizinha.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_05.png"
          />
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_camargos_06.png"
          />
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Outra comunidade com a qual Camargos estabelecia relações
                sócio-econômicas era o distrito de Santa Rita Durão, cujo acesso
                ocorria pela estrada que atravessava Bento Rodrigues,
                percorrendo-se um tranquilo caminho de terra. Com a destruição
                dessa comunidade, foram inviabilizados também os contatos
                anteriormente existentes com Santa Rita. O acesso passou a ser
                feito pela MG-129, rodovia asfaltada que liga Mariana a Santa
                Bárbara. Desta forma, comunidades vizinhas passaram a se
                comunicar somente por um caminho distante. Salienta-se que, além
                do caminho por Bento Rodrigues estar destruído como toda a
                comunidade, o acesso a Bento é controlado pelas empresas
                mineradoras, por meio de vigília e guarita.
              </p>
              <p>
                A rota que anteriormente era feita por muitos turistas em busca
                de esportes de aventura, a exemplo dos ciclistas, foi impedida a
                partir da destruição de Bento Rodrigues e em parte do
                subdistrito de Camargos. Os visitantes que atravessavam Bento e
                se dirigiram a Camargos não frequentam mais a última, As
                atividades econômicas relacionadas ao turismo de aventura foram
                assim extintas, já que não existe mais a movimentação e a
                circulação desses turistas, que gerava renda e mantinham o
                comércio e prestação de serviços local, como restaurantes,
                pousadas, bares.
              </p>
              <p style="text-align: center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>2</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> As informações a respeito do histórico e da
              caracterização de Camargos tiveram como base principal o
              Diagnóstico preliminar dos bens culturais identificados no
              território atingido em Mariana pelo rompimento da barragem de
              Fundão, elaborado em 2017 pela empresa Pólen Consultoria
              Patrimônio e Projetos Ltda. O Diagnóstico foi encomendado pela
              Comissão dos Atingidos e pela Cáritas Brasileira MG e juntamente
              com os dados do cadastro realizado pela Cáritas, possibilitou um
              entendimento mais amplo das relações culturais, sociais e
              econômicas que foram consolidadas nesse território ao longo dos
              séculos. O trabalho abrange aspectos geográficos, históricos,
              arqueológicos, arquitetônicos, antropológicos e museológicos.
              Contudo, ainda se trata de um estudo e diagnóstico preliminar,
              sendo necessário a ampliação do conhecimento sobre as comunidades
              atingidas.
            </p>
            <p style="margin-bottom: 0;">
              <sup>2</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
